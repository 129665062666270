import React, { useState, useEffect } from 'react'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

function preventDefault(event) {
  event.preventDefault()
}

function DataTable({ title, tableRows }) {
  const [cols, setCols] = useState([])

  useEffect(() => {
    if (tableRows?.length > 0) setCols(Object.keys(tableRows[0]))
  }, [tableRows])

  return (
    <>
      <Typography variant="h3">{title}</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={col}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow key={`row-${index}`}>
              {cols.map((col) => (
                <TableCell key={`${col}-${index}`}>{row[col]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more
      </Link>
    </>
  )
}

export default DataTable
