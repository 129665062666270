import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const upAndDown = keyframes`
  0%, 100% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(6px);
  }
`

const StyledTitleSection = styled(Box)`
  background-color: rgb(245, 245, 245);
  display: flex;
  height: 100vh;
  padding-top: 10vh;
  & > .MuiContainer-root {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
`

const DownIcon = styled(ArrowDownwardIcon)`
  animation: ${upAndDown} 2s linear infinite;
`

function TitleSection() {
  return (
    <StyledTitleSection>
      <Container maxWidth="md" sx={{ '& > .MuiTypography-root': { mb: 8 } }}>
        <Typography fontSize="64px" lineHeight="64px">
          Did your health insurance company deny a claim or a request for
          services?
        </Typography>
        <Container maxWidth="sm">
          <Typography color="textSecondary" sx={{ mb: 16 }}>
            ZZZZAnd the denial letter is confusing and difficult to understand?
            <br />
            Did the insurance company deny a large bill from the hospital for
            emergency services or the doctor who treated you in the emergency
            department because she does not participate in the insurance
            company’s network? And now the provider is expecting you to pay the
            billed charges?
          </Typography>
          <Typography fontWeight="medium">
            Appeal4YourHealth can help you appeal a denied claim or requested
            procedure
          </Typography>
        </Container>
        <DownIcon sx={{ color: 'text.secondary' }} />
      </Container>
    </StyledTitleSection>
  )
}

export default TitleSection
