import React from 'react'

import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'

function DatePicker({
  date,
  setDate,
  label,
  inputFormat = 'MM/dd/yyyy',
  inputProps,
}) {
  const handleChange = (newDate) => {
    setDate(newDate)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        inputFormat={inputFormat}
        value={date}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} {...inputProps} />}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
