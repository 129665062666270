import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataTable from 'components/common/DataTable'

function DIYIntakeLog() {
  const sampleLogs = [
    {
      data: new Date().toString(),
      person: 'Jane Doe',
      case: 'Case #123',
      description: 'Description of the log',
      timeWorked: '1 hour',
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <DataTable title="Time Expense Log" tableRows={sampleLogs} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DIYIntakeLog
