import React from 'react'

import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'

import SingleTitleLayout from 'layouts/SingleTitle'

function DIYLetterDownload() {
  const downloadLinks = ['Test1.pdf', 'Test2.pdf', 'Test3.pdf']

  return (
    <SingleTitleLayout title="DIY Form">
      <Stack spacing={2} sx={{ textAlign: 'center' }}>
        {downloadLinks.map((dl) => (
          <Link href="#" key={dl}>
            {dl}
          </Link>
        ))}
      </Stack>
    </SingleTitleLayout>
  )
}

export default DIYLetterDownload
