import React, { useEffect } from 'react'
import { Formik } from 'formik'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import MuiAlert from '@mui/material/Alert'
import spacing from '@mui/system/spacing'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import ActionButtons from 'components/common/ActionButtons'
import FieldGroup from './FieldGroup'

const Alert = styled(MuiAlert)(spacing)

const SubActionButton = styled(Typography)`
  float: right;
  margin-top: ${({ theme }) => `-${theme.rh(16)}`};
  margin-bottom: ${({ theme }) => theme.rh(28)};
  height: ${({ theme }) => theme.rh(16)};
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`

function FormRenderer({
  renderingData,
  headerActionProps,
  subActionProps,
  footerActionProps,
  innerRef,
  validate,
  validationSchema,
  handleSubmit,
  defaultValues,
}) {
  let initialValues = defaultValues

  const initialStatus = {
    errors: {
      submit: '',
    },
  }

  useEffect(() => {
    if (!defaultValues) {
      initialValues = {}
      renderingData.forEach((field) => {
        initialValues[field.field] = ''
      })
    }
  }, [renderingData])

  return (
    <Formik
      initialValues={initialValues ?? {}}
      initialStatus={initialStatus}
      onSubmit={handleSubmit}
      innerRef={innerRef}
      enableReinitialize
      validate={validate}
      validationSchema={validationSchema}
      validateOnChange
      validateOnChange={false}
    >
      {({ status, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {status.errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {status.errors.submit}
            </Alert>
          )}
          {headerActionProps && (
            <Box sx={{ paddingY: 6 }}>
              <ActionButtons actionProps={headerActionProps} />
            </Box>
          )}
          {subActionProps && <SubActionButton {...subActionProps} />}
          <Stack spacing={6}>
            {renderingData.map((fieldData, fieldIndex) => (
              <FieldGroup
                key={`${fieldData.path}-${fieldIndex}`}
                fieldData={fieldData}
              />
            ))}
          </Stack>
          {footerActionProps && (
            <Box sx={{ paddingY: 8 }}>
              <ActionButtons footer actionProps={footerActionProps} />
            </Box>
          )}
        </form>
      )}
    </Formik>
  )
}

FormRenderer.propTypes = {
  headerActionProps: PropTypes.arrayOf(PropTypes.object),
  subActionProps: PropTypes.object,
  footerActionProps: PropTypes.arrayOf(PropTypes.object),
  innerRef: PropTypes.object,
}

export default FormRenderer
