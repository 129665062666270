import React from 'react'

function UserDetail() {
  return (
    <div>
      <h1>User information</h1>
    </div>
  )
}

export default UserDetail
