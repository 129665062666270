import React from 'react'

function CaseDetal() {
  return (
    <div>
      <h1>CaseDetail</h1>
    </div>
  )
}

export default CaseDetal
