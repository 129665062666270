import React from 'react'
import { useFormikContext } from 'formik'

import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import DatePicker from 'components/common/DatePicker'

function DateField({ fieldData }) {
  const { values, handleChange, errors } = useFormikContext()
  const { field, display, options, large, inputProps } = fieldData

  const setDate = (date) => {
    handleChange({
      target: {
        name: field,
        value: date,
      },
    })
  }

  return (
    <DatePicker
      date={values[field]}
      setDate={setDate}
      label={display}
      inputProps={{
        ...inputProps,
        error: Boolean(errors[field]),
        helperText: errors[field],
      }}
    />
  )
}

export default DateField
