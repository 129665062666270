import React, { useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import FormRenderer from 'components/common/FormRenderer'

import useAuth from 'hooks/context/useAuth'

function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const formikRef = useRef(null)
  const { resetPassword } = useAuth()

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)

    try {
      await resetPassword(values)

      setSuccess(true)
    } catch (err) {
      setErrors({
        submit: err.response?.data ?? 'An error has occurred',
      })

      setLoading(false)
    }
  }

  const renderingData = [
    {
      field: 'current_password',
      display: 'Current password',
      type: 'password',
    },
    {
      field: 'new_password',
      display: 'New password',
      type: 'password',
    },
    {
      field: 'new_password_confirm',
      display: 'Confirm new password',
      type: 'password',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
      disabled: loading,
    },
  ]

  return (
    <Box>
      <Typography component="h1" variant="h1" mb={4}>
        Reset Password
      </Typography>
      {success ? (
        <Alert severity="success">
          Your password has been successfully reset.
        </Alert>
      ) : (
        <>
          {formikRef?.current?.errors?.submit && (
            <Alert severity="warning" open={success}>
              {formikRef.current.errors.submit}
            </Alert>
          )}
        </>
      )}
      {!success && (
        <FormRenderer
          renderingData={renderingData}
          footerActionProps={footerActionProps}
          innerRef={formikRef}
          handleSubmit={handleSubmit}
        />
      )}
    </Box>
  )
}

export default ResetPassword
