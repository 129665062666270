import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataTable from 'components/common/DataTable'

function DIYIntakeLog() {
  const sampleLogs = [
    {
      email: 'sample@sample.com',
      datetime: new Date().toString(),
      stripeTransactionId: 'sample-transaction-id',
    },
    {
      email: 'sample@sample.com',
      datetime: new Date().toString(),
      stripeTransactionId: 'sample-transaction-id',
    },
    {
      email: 'sample@sample.com',
      datetime: new Date().toString(),
      stripeTransactionId: 'sample-transaction-id',
    },
    {
      email: 'sample@sample.com',
      datetime: new Date().toString(),
      stripeTransactionId: 'sample-transaction-id',
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <DataTable title="DIY Intake Log" tableRows={sampleLogs} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DIYIntakeLog
