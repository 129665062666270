import React from 'react'

import FormRenderer from 'components/common/FormRenderer'

import SingleTitleLayout from 'layouts/SingleTitle'

function DIYForm() {
  const renderingData = [
    {
      field: 'email',
      display: 'Email address',
      type: 'email',
    },
    {
      field: 'credit-card',
      display: 'Credit card information',
      fieldType: 'credit-card',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
    },
  ]

  return (
    <SingleTitleLayout title="DIY Form">
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
      />
    </SingleTitleLayout>
  )
}

export default DIYForm
