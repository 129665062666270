import React, { useReducer, createContext, useEffect } from 'react'

import axios from 'utils/axios'

import isValidToken from 'helpers/auth/isValidToken'

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: {
    email: '',
    accessToken: '',
    accessTokenExpires: '',
  },
  challenge: '',
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, isAuthenticated, ...rest } = state

  if (isValidToken(state.user)) localStorage.userState = JSON.stringify(rest)
  else delete localStorage.userState
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isInitialized: true,
        user: action.payload.user,
        challenge: action.payload.challenge,
      }
    case 'LOGIN':
      return {
        isInitialized: true,
        user: action.payload.user,
        challenge: action.payload.challenge,
      }
    case 'UPDATE_TOKEN': {
      const { accessToken, accessTokenExpires } = action.payload

      // set the authorization header for all future requests
      axios.defaults.headers.Authorization = `Bearer ${accessToken}`

      return {
        ...state,
        user: {
          ...state.user,
          accessToken,
          accessTokenExpires,
        },
      }
    }
    case 'UPDATE_CHALLENGE': {
      const { challenge } = action.payload

      return {
        ...state,
        challenge,
      }
    }
    case 'SET_AUTHENTICATION': {
      const { isAuthenticated } = action.payload

      return {
        ...state,
        isAuthenticated,
      }
    }
    default:
      return state
  }
}

const AuthContext = createContext(null)

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const updateToken = ({ token, token_expires }) => {
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: {
        accessToken: token,
        accessTokenExpires: token_expires,
      },
    })
  }

  const login = async ({ email, password }) => {
    // axios amends base API endpoint by default
    // see utils/axios for default settings
    const res = await axios.post(
      `/api/auth/login`,
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const loginData = res.data
    const accessToken = loginData.token

    // store user data in global state
    dispatch({
      type: 'INITIALIZE',
      payload: {
        user: {
          email,
          accessToken,
          accessTokenExpires: loginData.token_expires,
        },
        challenge: loginData.challenge,
      },
    })

    updateToken(loginData)

    return res.data
  }

  const resetPassword = async ({
    current_password,
    new_password,
    new_password_confirm,
  }) => {
    const res = await axios.post('/api/user/resetPassword', {
      current_password,
      new_password,
      new_password_confirm,
    })

    if (res.status !== 204) {
      throw new Error('Password reset failed')
    }
  }

  const setupMFA = async ({ mfaSecret, code }) => {
    const res = await axios.post('/api/auth/mfaSetup', {
      secret: mfaSecret,
      code,
    })

    updateToken(res.data)
  }

  const enterMFACode = async ({ code }) => {
    const res = await axios.post(`/api/auth/mfa`, {
      code: code?.toString(),
    })

    updateToken(res.data)

    dispatch({
      type: 'UPDATE_CHALLENGE',
      payload: {
        challenge: '',
      },
    })
  }

  const initialize = () => {
    try {
      const userState = JSON.parse(
        localStorage.userState ?? JSON.stringify(initialState)
      )

      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...userState,
        },
      })

      updateToken({
        token: userState.user.accessToken,
        token_expires: userState.user.accessTokenExpires,
      })
    } catch (err) {
      storeState(initialState)
    }
  }

  useEffect(initialize, [])

  const isUserAuthenticated = () => {
    return isValidToken(state.user) && state.challenge === ''
  }

  const setAuthentication = (isAuthenticated) => {
    dispatch({
      type: 'SET_AUTHENTICATION',
      payload: {
        isAuthenticated,
      },
    })
  }

  // store local state whenever state changes in memory
  useEffect(() => {
    if (state.isInitialized) {
      const isAuthenticated = isUserAuthenticated()

      if (state.isAuthenticated !== isAuthenticated)
        setAuthentication(isAuthenticated)

      storeState(state)
    }
  }, [state])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        resetPassword,
        setupMFA,
        enterMFACode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
