import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

import FormRenderer from 'components/common/FormRenderer'

import useAuth from 'hooks/context/useAuth'

function Login() {
  const formikRef = useRef(null)
  const navigate = useNavigate()
  const { login } = useAuth()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true)

    try {
      await login(values)
    } catch (err) {
      setErrors({
        submit: err.response?.data?.message ?? 'An error has occurred',
      })
      setLoading(false)
    }
  }

  const renderingData = [
    {
      field: 'email',
      display: 'Email address',
      type: 'email',
    },
    {
      field: 'password',
      display: 'Password',
      type: 'password',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
      disabled: loading,
    },
  ]

  const error = formikRef?.current?.errors?.submit

  return (
    <Box sx={{ width: '100%' }}>
      {error && (
        <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
      )}
      <Typography component="h1" variant="h1" mb={4}>
        Sign In
      </Typography>
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
      />
    </Box>
  )
}

export default Login
