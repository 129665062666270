import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import MuiListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import LayersIcon from '@mui/icons-material/Layers'

function ListItem({ to, ...props }) {
  const location = useLocation()
  const selected = location.pathname === to

  return (
    <MuiListItem
      button
      component={Link}
      to={to}
      sx={{ bgcolor: selected && 'rgba(0, 0, 0, 0.04)' }}
      {...props}
    />
  )
}

export const mainListItems = (
  <Box>
    <ListItem to="/console/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem to="/console/diy-intake-log">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="DIY Intake Log" />
    </ListItem>
    <ListItem to="/console/time-expense-log">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Time/Expense Log" />
    </ListItem>
  </Box>
)

export const caseListItems = (
  <Box>
    <ListSubheader inset>Case</ListSubheader>
    <ListItem to="/console/case-browser">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Case Browser" />
    </ListItem>
    <ListItem to="/console/case-triage">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Case Triage" />
    </ListItem>
    <ListItem to="/console/case-detail">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Case Detail" />
    </ListItem>
  </Box>
)

export const userListItems = (
  <Box>
    <ListSubheader inset>User</ListSubheader>
    <ListItem to="/console/user-create-edit">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Create & Edit" />
    </ListItem>
    <ListItem to="/console/user-detail">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Detail" />
    </ListItem>
  </Box>
)
