import React, { useState } from 'react'

import TextField from '@mui/material/TextField'

function AsyncTextField({ value, onChange, ...props }) {
  const [localValue, setValue] = useState(value)

  return (
    <TextField
      {...props}
      value={localValue}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onChange}
    />
  )
}

export default AsyncTextField
