import React from 'react'
import { Navigate } from 'react-router-dom'

import useAuth from 'hooks/context/useAuth'

import isValidToken from 'helpers/auth/isValidToken'

function LoginGuard({ children }) {
  const { user } = useAuth()

  if (isValidToken(user)) return <Navigate to="/console" replace />

  return <>{children}</>
}

export default LoginGuard
