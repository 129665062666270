import React from 'react'
import { Navigate } from 'react-router-dom'

// layouts
import PublicFacingLayout from 'layouts/PublicFacing'
import ConsoleLayout from 'layouts/Console'
import AuthLayout from 'layouts/Auth'

// guards
import AuthGuard from 'components/guards/AuthGuard'
import LoginGuard from 'components/guards/LoginGuard'
import ConsoleGuestGuard from 'components/guards/ConsoleGuestGuard'

// pages
import Home from 'pages/Home'
import DIYForm from 'pages/DIYForm'
import DIYLetterDownload from 'pages/DIYLetterDownload'
import CustomerIntakeForm from 'pages/CustomerIntakeForm'
import Dashboard from 'pages/console/Dashboard'
import DIYIntakeLog from 'pages/console/DIYIntakeLog'
import CaseBrowser from 'pages/console/CaseBrowser'
import CaseTriage from 'pages/console/CaseTriage'
import CaseDetail from 'pages/console/CaseDetail'
import UserCreateEdit from 'pages/console/UserCreateEdit'
import UserDetail from 'pages/console/UserDetail'
import TimeExpenseLog from 'pages/console/TimeExpenseLog'
import CustomerLogin from 'pages/console/CustomerLogin'

// User login
import UserLogin from 'pages/console/UserLogin'
import UserLoginPage from 'pages/console/UserLogin/Login'
import UserLoginResetPassword from 'pages/console/UserLogin/ResetPassword'
import UserLoginMFA from 'pages/console/UserLogin/MFA'
import UserLoginMFACode from 'pages/console/UserLogin/MFACode'

const routes = [
  {
    path: '/',
    element: <PublicFacingLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'diy',
        element: <DIYForm />,
      },
      {
        path: 'diy-letter-download',
        element: <DIYLetterDownload />,
      },
      {
        path: 'customer-intake',
        element: <CustomerIntakeForm />,
      },
    ],
  },
  {
    path: '/console/customer-login',
    element: (
      <AuthLayout>
        <CustomerLogin />
      </AuthLayout>
    ),
  },
  {
    path: '/console/user-login',
    element: (
      <AuthLayout>
        <ConsoleGuestGuard>
          <UserLogin />
        </ConsoleGuestGuard>
      </AuthLayout>
    ),
    children: [
      {
        path: '',
        element: (
          <LoginGuard>
            <UserLoginPage />
          </LoginGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
      {
        path: 'challenge',
        children: [
          {
            path: 'auth-code',
            element: (
              <AuthGuard>
                <UserLoginMFACode />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/console',
    element: <ConsoleLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/console/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'diy-intake-log',
        element: <DIYIntakeLog />,
      },
      {
        path: 'case-browser',
        element: <CaseBrowser />,
      },
      {
        path: 'case-triage',
        element: <CaseTriage />,
      },
      {
        path: 'case-detail',
        element: <CaseDetail />,
      },
      {
        path: 'user-create-edit',
        element: <UserCreateEdit />,
      },
      {
        path: 'user-detail',
        element: <UserDetail />,
      },
      {
        path: 'time-expense-log',
        element: <TimeExpenseLog />,
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
    ],
  },
]

export default routes
