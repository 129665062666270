import React from 'react'

function UserCreateEdit() {
  return (
    <div>
      <h1>List of Users</h1>
    </div>
  )
}

export default UserCreateEdit
