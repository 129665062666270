import { useState } from 'react'

import axios from 'utils/axios'

const useIntakeForm = () => {
  const dateToString = (date) => {
    try {
      return date.toISOString()
    } catch (err) {
      return ''
    }
  }

  const submitForm = async (formValues, setStatus) => {
    try {
      const res = await axios.post('/api/customer/intake', {
        customer: {
          customer_email: formValues.customer_email,
          customer_phone: formValues.customer_phone,
          customer_first_name: formValues.customer_first_name,
          customer_last_name: formValues.customer_last_name,
          customer_insurance_company: formValues.customer_insurance_company,
          customer_address_line_1: formValues.customer_address_line_1,
          customer_address_line_2: formValues.customer_address_line_2,
          customer_address_city: formValues.customer_address_city,
          customer_address_state: formValues.customer_address_state?.value,
          customer_address_zip: formValues.customer_address_zip,
          customer_timezone: formValues.customer_timezone,
          customer_subscriber_relation: formValues.customer_subscriber_relation,
          customer_best_call_time: formValues.customer_best_call_time,
        },
        client_case: {
          client_case_timing: formValues.client_case_timing,
          client_case_denied_datetime: dateToString(
            formValues.client_case_denied_datetime
          ),
          client_case_due_date: dateToString(formValues.client_case_due_date),
          client_case_appeal_type: formValues.client_case_appeal_type,
          client_case_service_level: formValues.client_case_service_level,
        },
      })
    } catch (err) {
      setStatus({
        errors: { submit: err.response?.data ?? 'An error has occurred' },
      })
    }
  }

  return {
    submitForm,
  }
}

export default useIntakeForm
