import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import isValidToken from 'helpers/auth/isValidToken'

import useAuth from 'hooks/context/useAuth'

function AuthGuard({ children }) {
  const location = useLocation()
  const { user, challenge, isAuthenticated, isInitialized } = useAuth()

  if (!isInitialized) return <div />

  // if user unauthenticated, redirect to login
  if (!isValidToken(user)) return <Navigate to="/console/user-login" replace />

  // if user logged in but requires 2FA, redirect to correct challenge
  if (
    challenge === 'MFA_AUTH' &&
    location.pathname !== '/console/user-login/challenge/auth-code'
  )
    return <Navigate to="/console/user-login/challenge/auth-code" replace />

  return <>{children}</>
}

export default AuthGuard
