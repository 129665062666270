import React from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataTable from 'components/common/DataTable'

function CaseBrowser() {
  const sampleCases = [
    {
      id: 1,
      customerId: 1,
      userId: 2,
      dateCreated: '2020-01-01',
      dateOfDenial: '2020-01-01',
      denialTiming: 'Pre-Care',
      dueDate: '2020-01-01',
      appealType: 'Clinical',
      serviceLevel: 'Consultant Report',
      status: 'Closed',
      determination: 'Accepted',
    },
    {
      id: 1,
      customerId: 1,
      userId: 2,
      dateCreated: '2020-01-01',
      dateOfDenial: '2020-01-01',
      denialTiming: 'Pre-Care',
      dueDate: '2020-01-01',
      appealType: 'Clinical',
      serviceLevel: 'Consultant Report',
      status: 'Closed',
      determination: 'Accepted',
    },
    {
      id: 1,
      customerId: 1,
      userId: 2,
      dateCreated: '2020-01-01',
      dateOfDenial: '2020-01-01',
      denialTiming: 'Pre-Care',
      dueDate: '2020-01-01',
      appealType: 'Clinical',
      serviceLevel: 'Consultant Report',
      status: 'Closed',
      determination: 'Accepted',
    },
    {
      id: 1,
      customerId: 1,
      userId: 2,
      dateCreated: '2020-01-01',
      dateOfDenial: '2020-01-01',
      denialTiming: 'Pre-Care',
      dueDate: '2020-01-01',
      appealType: 'Clinical',
      serviceLevel: 'Consultant Report',
      status: 'Closed',
      determination: 'Accepted',
    },
    {
      id: 1,
      customerId: 1,
      userId: 2,
      dateCreated: '2020-01-01',
      dateOfDenial: '2020-01-01',
      denialTiming: 'Pre-Care',
      dueDate: '2020-01-01',
      appealType: 'Clinical',
      serviceLevel: 'Consultant Report',
      status: 'Closed',
      determination: 'Accepted',
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <DataTable title="Cases" tableRows={sampleCases} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CaseBrowser
