import React from 'react'
import { useFormikContext } from 'formik'

import MuiAutocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

function AutocompleteField({ fieldData }) {
  const { values, handleChange, errors } = useFormikContext()
  const handleUpdateChange = ({ name, value }) => {
    handleChange({ target: { name, value } })
  }

  const { field, display, type, inputProps, options } = fieldData

  return (
    <MuiAutocomplete
      type={type}
      id={field}
      name={field}
      label={display}
      value={values[field] || null}
      onChange={(e, value) => handleUpdateChange({ name: field, value })}
      fullWidth
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          label={display}
          error={Boolean(errors[field])}
          helperText={errors[field]}
        />
      )}
      getOptionLabel={(option) => option.display ?? option}
      isOptionEqualToValue={(option, value) => option.value === value}
    />
  )
}

export default AutocompleteField
